import { Component, Inject, OnInit, Optional } from '@angular/core';
import { DIALOG_DATA } from '../../../../global.variable';
import { ClientsService } from '../../../pages/pages/clients/clients.service';
import { LoadingSpinnerService } from '../../services/loading-spinner.service';
import { ToasterService } from '../../services/toaster.service';
import { ContactComponent } from '../contact/contact.component';
import { DialogRef } from '../modal-dialogue/model-dialogue.ref';
import { ModelDialogueService } from '../modal-dialogue/model-dialogue.service';

@Component({
  selector: 'gtapp-client-site-contacts',
  templateUrl: './client-site-contacts.component.html',
  styleUrl: './client-site-contacts.component.scss',
})
export class ClientSiteContactsComponent implements OnInit {
  clientData: any;
  siteData: any;
  selectedUsers: any = [];

  contactType: string = 'jc';

  clientContactList: any = [];
  contactLookupList: any = [];
  constructor(
    @Optional() protected dialogRef: DialogRef,
    private customerService: ClientsService,
    @Inject(DIALOG_DATA) public dialogueData: any,
    private spinnerService: LoadingSpinnerService,
    private toasterService: ToasterService,
    private dialogService: ModelDialogueService
  ) {}
  ngOnInit(): void {
    if (this.dialogueData) {
      this.clientData = this.dialogueData?.clientData;
      this.siteData = this.dialogueData?.siteData;
      this.contactType = this.dialogueData?.contactType || 'jc';
      this.formatSelectedUsers(this.dialogueData?.selectedUsers || []);
    }

    this.getExistingContacts();
  }
  onCloseDialogue(val = false) {
    this.dialogRef.close(val);
  }
  formatSelectedUsers(selectedUsers: []) {
    if (this.contactType === 'jc') {
      this.selectedUsers = selectedUsers;
    } else {
      this.selectedUsers = [];
      selectedUsers?.forEach((contact: any) => {
        let formattedContact = contact;
        if (!contact?.pk && contact?.subscriber_user_id)
          // case where the data is returned from the add contact view
          formattedContact.pk = contact?.subscriber_user_id;

        this.selectedUsers.push(formattedContact);
      });
    }
  }
  getExistingContacts() {
    let clientId = this.clientData?.id
      ? this.clientData?.id
      : this.siteData?.company?.id;
    this.customerService
      .searchLocationContacts({ union: 1, company_id: clientId })
      .subscribe((response: any) => {
        if (response['status'] == 'success') {
          this.clientContactList = response?.data;

          setTimeout(() => {
            this.makeSelectionChanges();
          }, 100);
        }
      });
  }
  addNewContact() {
    const newContactRef = this.dialogService.open(ContactComponent, {
      data: {
        siteData: this.siteData,
        clientData: this.clientData,
        contactType: this.contactType,
        addNewSiteContact: true,
      },
    });
    newContactRef.afterClosed().subscribe((value: any) => {
      if (value !== false) {
        this.formatSelectedUsers(value?.data || []);
        this.getExistingContacts();
      }
    });
  }
  addContactToSelected(contactI: any) {
    if (contactI?.key === 0) {
      this.addNewContact();
    } else {
      let contact = {
        pk: contactI?.pk,
        email: contactI?.user_email,
        first_name: contactI?.user_first_name,
        last_name: contactI?.user_last_name,
        full_name: contactI?.user_full_name,
        location_contact_id: contactI?.location_contact_id,
        mobile_number: contactI?.user_mobile_number,
      };
      if (
        this.selectedUsers?.some(
          (selectedContact: any) => selectedContact?.pk === contact?.pk
        )
      ) {
        this.toasterService.setMessage({
          errorMessage: 'Contact Already Selected',
          successMessage: '',
        });
      } else {
        if (
          !this.selectedUsers?.some(
            (selectedContact: any) => selectedContact?.pk === contact?.pk
          )
        ) {
          this.selectedUsers.push(contact);
        }
      }

      setTimeout(() => {
        this.makeSelectionChanges();
      }, 100);
    }
  }
  removeContactFromSelected(contact: any) {
    this.selectedUsers = this.selectedUsers?.filter(
      (selectedContact: any) => selectedContact?.pk !== contact?.pk
    );

    setTimeout(() => {
      this.makeSelectionChanges();
    }, 100);
  }

  makeSelectionChanges() {
    this.selectedUsers?.forEach((selectedContact: any) => {
      if (
        !this.clientContactList?.some(
          (contact: any) => contact?.pk === selectedContact?.pk
        )
      ) {
        this.clientContactList.push(selectedContact);
      }
    });
    this.clientContactList.forEach((contact: any) => {
      contact.selected = this.selectedUsers?.some(
        (selectedContact: any) => selectedContact?.pk === contact?.pk
      );
    });
    this.clientContactList.sort((a: any, b: any) => {
      if (a.selected === b.selected) {
        return 0;
      }
      return a.selected ? -1 : 1;
    });
  }
  addRemoveContact(contact: any) {
    contact.selected = !contact?.selected;
    if (contact.selected) {
      this.addContactToSelected(contact);
    } else {
      this.removeContactFromSelected(contact);
    }
  }

  contactLookup(event: any) {
    this.contactLookupList = this.clientContactList.filter(
      (item: any) =>
        item?.user_full_name?.toLowerCase().includes(event?.target?.value) ||
        item?.user_email?.toLowerCase().includes(event?.target?.value)
    );
  }
  addRemoveUsers() {
    if (this.clientData?.id) {
      this.addRemoveClientReportContacts();
    } else {
      this.addRemoveSiteReportContacts();
    }
  }
  async getRequestData(listData: any) {
    return new Promise(async (resolve) => {
      if (!listData.length) resolve(null);
      let data: any = {};
      await Promise.all(
        listData.map((item: any) => {
          let contact = {
            first_name: item?.first_name,
            last_name: item?.last_name,
            mobile_number: item?.mobile_number,
          };

          data[item.pk] = contact;
        })
      );

      resolve(data);
    });
  }
  async addRemoveClientReportContacts() {
    let userIds = await this.getRequestData(this.selectedUsers);
    this.spinnerService.show();
    let params: any = {};
    if (this.contactType === 'jc') {
      params['manage_report_users'] = 1;
    } else {
      params['manage_company_contacts'] = 1;
    }

    this.customerService
      .updateClient(
        this.clientData?.id,
        {
          user_ids: userIds,
        },
        params
      )
      .then((response: any) => {
        if (response['status'] == 'success') {
          this.onCloseDialogue(response);
        } else {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
        this.spinnerService.hide();
      });
  }

  async addRemoveSiteReportContacts() {
    let userIds = await this.getRequestData(this.selectedUsers);
    this.spinnerService.show();
    let params: any = {};
    if (this.contactType === 'jc') {
      params['manage_report_users'] = 1;
    } else {
      params['manage_site_contacts'] = 1;
    }

    this.customerService
      .updateSite(
        this.siteData?.id,
        {
          user_ids: userIds,
        },
        params
      )
      .then((response: any) => {
        if (response['status'] == 'success') {
          this.onCloseDialogue(response);
        } else {
          this.toasterService.setMessage({
            successMessage: '',
            errorMessage: response['message'],
          });
        }
        this.spinnerService.hide();
      });
  }
}
