import { formatDate } from '@angular/common';
import { InjectionToken } from '@angular/core';
declare let L: any;

import { v4 as uuidv4 } from 'uuid';
// Need to review the variable of nebular and use the variable of Bpptstrap
export const DIALOG_DATA = new InjectionToken<any>('DIALOG_DATA');

export const currentTheme: any = 'default';

export function getFormattedDate(val?: any) {
  let date = val ? new Date(val) : new Date();
  let year = date.getFullYear();
  let month = String(date.getMonth() + 1).padStart(2, '0');
  let day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`; // YYYY-MM-DD
}
export function removeYIfYPresent(inputString: string): string {
  if (inputString?.includes('y')) {
    return inputString.replace(/y/g, '');
  }
  return inputString;
}
export function formatDateTimeStamp(
  value: any,
  format: string,
  timeZone: string
) {
  return formatDate(value, removeYIfYPresent(format), timeZone);
}
export function getFormattedTime(val?: any) {
  let date = val ? new Date(val) : new Date();
  return date.toTimeString().split(' ')[0].slice(0, 5); // HH:MM
}

export function getFormattedDateTime(val?: any) {
  return `${getFormattedDate(val)}T${getFormattedTime(val)}:00`;
}

export const isLargeScreen: Boolean = !(
  (window.innerWidth < 1000 && navigator.maxTouchPoints > 0) ||
  // added this to get btns in the desktop mobile vew.
  window.innerWidth < 770
);

export function isLargeScreenFun(): Promise<boolean> {
  const promise = new Promise<boolean>((resolve) => {
    setTimeout(() => {
      resolve(
        !(
          (window.innerWidth < 1000 && navigator.maxTouchPoints > 0) ||
          // added this to get btns in the desktop mobile vew.
          window.innerWidth < 770
        )
      );
    }, 500);
  });

  return promise;
}

export function getWelfareCheckInterval(hours: number) {
  let intervalData = [
    { name: 'Disabled', value: 0 },
    { name: 'Every 15 mins', value: 15 },
    { name: 'Every 30 mins', value: 30 },
  ];
  if (hours > 1) {
    intervalData.push({
      name: 'Every Hour',
      value: 60,
    });
  }
  if (hours > 2) {
    intervalData.push({
      name: 'Every 2 Hours',
      value: 120,
    });
  }
  return [intervalData, { 5: 1, 15: 3, 30: 5, 60: 10, 120: 15 }];
}

export function getInitalDateRangeForListData(cardColumns: any = []) {
  let filteredItems = cardColumns?.filter(
    (item: any) => item?.datetimeObj == true
  );
  let keyValue =
    filteredItems?.find((item: any) => item?.default) || filteredItems[0];
  if (keyValue?.dateRange) {
    return keyValue?.dateRange;
  } else {
    const today = new Date();
    today.setDate(new Date().getDate() - 1);
    return {
      start: today,
      end: new Date(),
    };
  }
}

export function dateRangeValueConverter(inputDate: Date) {
  return String(formatDateTimeStamp(inputDate, 'd MMM y HH:mm', 'en_US'));
}
export const globalLicenseInfo = {
  icons: [
    {
      condition: function () {
        return {
          icon: 'fa-regular fa-trash-can fa-2x',
          color: 'danger',
          title: 'Delete Licence',
          confirm: true,
          show: 'top',
          type: 'delete',
        };
      },
    },
  ],
  details: [
    {
      nestedValue: (row: any) => {
        if (row?.['expiry_code'] == 2) {
          return 'EXPIRED';
        } else {
          return;
        }
      },
      cardRowStyle: {
        'color': 'var(--bs-danger)',
        'display': 'block',
        'font-size': '1.2rem',
        'font-weight': '700',
        'text-align': 'center',
      },
    },
    {
      name: 'license_class',
      cardRowStyle: {
        'font-size': 'small',
        'float': 'right',
      },
    },
    {
      name: 'license_number',
      cardRowStyle: { 'font-weight': 'bolder' },
    },
    {
      name: 'issuer_state',
      cardRowStyle: {
        'font-size': 'small',
        'float': 'right',
      },
    },

    {
      name: 'valid_till',
      cardRowStyle: {
        'font-size': 'small',
      },
    },
  ],
};

export const globalLocationContact = {
  individualDetails: [
    {
      nestedValue: (row: any) => {
        return row?.['user']?.['full_name'];
      },

      cardRowStyle: {
        'font-weight': 'bolder',
      },
    },
    {
      nestedValue: (row: any) => {
        return row?.['user']?.['email'];
      },

      cardRowStyle: {
        'font-size': 'small',
      },
    },
    {
      nestedValue: (row: any) => {
        return row?.['user']?.['mobile_number'];
      },
      cardRowStyle: {
        'font-size': 'small',
      },
    },
  ],
};

export const globalUserFeedbackStatusColor = {
  'COMPLETED': 'var(--bs-green)',
  'IN PROGRESS': 'var(--bs-yellow)',
  'ON HOLD': 'var(--bs-danger)',
  'N/A': 'var(--bs-green)',
  'NEW': 'var(--bs-gray-100)',
};
export const globalUserTypeColors = {
  registered: 'var(--color-success-400)',
  pending: 'var(--color-danger-400)',
};
let today = new Date();
today.setDate(today.getDate() - 1);
export const yesterday = today;

export const globalChartHeight: number = 500;

export const mapLayers = {
  'Google Hybrid': L.tileLayer(
    'https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',
    {
      maxZoom: 18,
      minZoom: 10,
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      attribution:
        '&copy; <a href="https://maps.google.com/help/terms_maps.html">Google Maps</a> ',
    }
  ),
  'Google Satlite': L.tileLayer(
    'https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
    {
      maxZoom: 18,
      minZoom: 10,
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      attribution:
        '&copy; <a href="https://maps.google.com/help/terms_maps.html">Google Maps</a> ',
    }
  ),
  'Default': L.tileLayer('https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
    maxZoom: 18,
    minZoom: 10,
    subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
    attribution:
      '&copy; <a href="https://maps.google.com/help/terms_maps.html">Google Maps</a> ',
  }),
};

export const browserGpsInfo = {
  // 0: { description: 'Location captured with high accuracy' },
  1: { description: 'Location may not be accurate.' },
  2: {
    description: 'Location is an approximation only.',
  },
  // 'high accuracy': { description: 'Location captured with high accuracy' },
  'low accuracy': { description: 'Location may not be accurate.' },
  'ip': {
    description: 'Location is an approximation only.',
  },
};

export function getDistanceUsingHaversine(
  latitude1: any,
  latitude2: any,
  longitude1: any,
  longitude2: any
) {
  let lon1 = (longitude1 * Math.PI) / 180;
  let lon2 = (longitude2 * Math.PI) / 180;
  let lat1 = (latitude1 * Math.PI) / 180;
  let lat2 = (latitude2 * Math.PI) / 180;

  // Haversine formula
  let dlon = lon2 - lon1;
  let dlat = lat2 - lat1;
  let a =
    Math.pow(Math.sin(dlat / 2), 2) +
    Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(dlon / 2), 2);

  let c = 2 * Math.asin(Math.sqrt(a));

  // Radius of earth in kilometers. Use 3956
  // for miles
  let r = 6371;

  // calculate the result

  return (c * r).toFixed(3);
}

export const cardDeleteActionIcon = [
  {
    condition: function (row: any) {
      return {
        icon: 'fa-regular fa-trash-can fa-2x',
        title: 'Delete',
        confirm: true,
        type: 'delete',
      };
    },
  },
];

export function fetchUniqueQrText() {
  let userData = JSON.parse(localStorage.getItem('userData') || '{}');
  let subscriberCode =
    'CP-' +
    userData?.subscriber?.name
      ?.replace(/[\W_]+/gi, '')
      ?.slice(0, 4)
      ?.toUpperCase() +
    '-' +
    uuidv4();

  return subscriberCode;
}

export function convertTime12to24(time12h: any) {
  const [time, modifier] = time12h.split(' ');

  let [hours, minutes] = time.split(':');

  if (hours == '12') {
    hours = '00';
  }

  if (modifier?.toUpperCase() === 'PM') {
    hours = parseInt(hours, 10) + 12;
  }

  return `${hours}:${minutes}`;
}
export function convertTime24to12(time: any) {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? ' am' : ' pm'; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
    time.splice(3, 1);
  }
  return time.join(''); // return adjusted time or original string
}

export function getFormattedRepeatDetail(
  repeatType: string,
  repeatDetails: any
) {
  let outputSring: any = '';
  if (repeatType === 'weekly') {
    outputSring = repeatDetails.join(', ');
  }
  if (repeatType === 'monthly') {
    if (repeatDetails?.every((item: any) => Number.isInteger(item))) {
      outputSring = repeatDetails.join(', ');
    } else {
      let value = '';
      let order = ['First', 'Second', 'Third', 'Fourth', 'Fifth']; // Add more if needed

      repeatDetails?.forEach(
        (item: any) => (value += `${order[item.position - 1]} ${item.week_day}`)
      );
      outputSring = value;
    }
  }
  return outputSring;
}

export function jobCardComponentDetail(
  statusDataDetails: any,
  stalePeriod: any,
  isAdminOrDispatchUser?: boolean
) {
  return [
    {
      name: 'status',
      conditionalRowStyle: (row: any) => {
        if (row?.status) {
          let style: any = { 'float': 'right', 'font-size': 'small' };

          statusDataDetails?.forEach((element: any) => {
            if (element.name === row.status) {
              style = {
                'color': `${element.additional_info?.style?.snubbed?.color}`,
                'background-color': `${element.additional_info?.style?.snubbed['background-color']}`,
                'border': '1px solid',
                'border-radius': '5px',
                'padding': '0 0.5rem',
                'float': 'right',
                'font-size': 'small',
              };
            }
          });

          return style;
        } else {
          return { 'float': 'right', 'font-size': 'small' };
        }
      },
    },

    {
      nestedValue: (row: any) => {
        if (row?.response_type) {
          return row?.service_type + ' - ' + row?.response_type;
        } else {
          return row?.service_type;
        }
      },
      cardRowStyle: { 'font-weight': 'bold' },
    },
    {
      nestedValue: (row: any) => {
        if (row?.roster_scheduler) {
          return `${
            row?.schedule_event_date
              ? row?.schedule_event_date
              : row?.roster_scheduler?.start_day
          } [${row?.roster_scheduler?.start_time} - ${
            row?.roster_scheduler?.end_time
          }]`;
        } else {
          return null;
        }
      },
      cardRowStyle: { 'font-weight': 'bold' },
    },

    {
      name: 'site_name',
      cardRowStyle: { 'font-weight': 'bold' },
    },
    {
      name: 'company',
    },

    {
      name: 'sites',
      cardRowStyle: { 'font-size': 'small' },
    },

    {
      nestedValue: (row: any) => {
        if (
          row?.status == 'Closed' ||
          row?.status_identity == 2 ||
          row?.status_identity == 3 ||
          row?.roster_scheduler
        ) {
          return ' ';
        } else {
          return (
            'LAST UPDATE: ' +
            String(
              formatDateTimeStamp(row?.modified_date, 'd MMM y HH:mm', 'en_US')
            )
          );
        }
      },
      conditionalRowStyle: (row: any) => {
        if (
          new Date(row?.modified_date).getTime() <
          new Date().setDate(new Date().getDate() - stalePeriod)
        ) {
          return {
            'background-color': 'var(--bs-red)',
            'color': 'var(--bs-gray-100)',
            'width': 'fit-content',
            'font-size': '0.7rem',
          };
        }
        return { 'font-size': '0.7rem' };
      },
    },

    {
      nestedValue: (row: any) => {
        if (row?.ext_job_id) {
          return row?.job_key + ' - ' + row?.ext_job_id;
        } else {
          return row?.job_key;
        }
      },
      cardRowStyle: {
        'font-size': 'small',
        'margin-top': '0.5rem',
      },
    },
    {
      nestedValue: (row: any) => {
        if (isAdminOrDispatchUser) {
          const isAssigneeEmpty =
            !row?.assignee ||
            row?.assignee.every((item: any) => item.trim() === '');
          if (isAssigneeEmpty) {
            return 'No Guard Assigned';
          } else if (row?.assignee) {
            return row.assignee.join(', '); // Join names with a comma and space
          }
        }
        return null;
      },
      conditionalRowStyle: (row: any) => {
        if (isAdminOrDispatchUser) {
          const isAssigneeEmpty =
            !row?.assignee ||
            row?.assignee.every((item: any) => item.trim() === '');
          if (isAssigneeEmpty) {
            return {
              'color': 'var(--bs-red)',
              'width': 'fit-content',
              'font-size': '1rem',
              'font-weight': '700',
              'padding-top': '0.1rem',
            };
          }
          return { 'margin-top': '0.5rem', 'font-weight': 'bold' };
        }
        return {};
      },
    },
  ];
}

export function nearestJobDetail(statusDataDetails: any, stalePeriod: any) {
  return [
    {
      name: 'status_name',
      conditionalRowStyle: (row: any) => {
        if (row?.status_name) {
          let style: any = { 'float': 'right', 'font-size': 'small' };

          statusDataDetails?.forEach((element: any) => {
            if (element.name === row.status_name) {
              style = {
                'color': `${element.additional_info?.style?.snubbed?.color}`,
                'background-color': `${element.additional_info?.style?.snubbed['background-color']}`,
                'border': '1px solid',
                'border-radius': '5px',
                'padding': '0 0.5rem',
                'float': 'right',
                'font-size': 'small',
              };
            }
          });

          return style;
        } else {
          return { 'float': 'right', 'font-size': 'small' };
        }
      },
    },

    {
      nestedValue: (row: any) => {
        if (row?.response_type_name) {
          return row?.job_type_name + ' - ' + row?.response_type_name;
        } else {
          return row?.job_type_name;
        }
      },
      cardRowStyle: { 'font-size': 'small' },
    },

    {
      name: 'site_name',
      cardRowStyle: { 'font-weight': 'bold' },
    },
    {
      nestedValue: (row: any) => {
        if (
          row?.job_type_name?.toLowerCase() === 'patrol' &&
          row?.checkpoints
        ) {
          return row?.checkpoints_remaining > 0
            ? `Checkpoint${row?.checkpoints == 1 ? '' : 's'} to Scan: ${
                row?.checkpoints_remaining
              } of ${row?.checkpoints}`
            : 'All Checkpoints Scanned';
        }
        return;
      },
      conditionalRowStyle: (row: any) => {
        if (row?.job_type_name?.toLowerCase() === 'patrol') {
          return {
            'margin-top': '0.5rem',
          };
        }
        return {};
      },
    },

    {
      name: 'site_address',
      cardRowStyle: { 'font-size': 'small' },
    },

    {
      nestedValue: (row: any) => {
        if (
          row?.last_modified_at &&
          !(
            row?.status == 'Closed' ||
            row?.status_identity == 2 ||
            row?.status_identity == 3 ||
            row?.job_type_name?.toLowerCase() === 'patrol'
          )
        ) {
          return (
            'LAST UPDATE: ' +
            String(
              formatDateTimeStamp(
                row?.last_modified_at,
                'd MMM y HH:mm',
                'en_US'
              )
            )
          );
        }
        return '';
      },
      conditionalRowStyle: (row: any) => {
        if (
          row?.last_modified_at &&
          new Date(row?.last_modified_at).getTime() <
            new Date().setDate(new Date().getDate() - stalePeriod)
        ) {
          return {
            'background-color': 'var(--bs-red)',
            'color': 'var(--bs-dark)',
            'width': 'fit-content',
            'font-size': '0.7rem',
          };
        }
        return { 'font-size': '0.7rem' };
      },
    },

    {
      nestedValue: (row: any) => {
        if (row?.external_job_id) {
          return row?.job_id + ' - ' + row?.external_job_id;
        } else {
          return row?.job_id;
        }
      },
      cardRowStyle: {
        'font-size': 'small',
        'margin-top': '0.5rem',
      },
    },
    // {
    //   nestedValue: (row: any) => {
    //     if (row?.start_time) {
    //       return `${row?.event_date} | ${row?.start_time} - ${row?.end_time}`;
    //     } else {
    //       return null;
    //     }
    //   },
    //   conditionalRowStyle: (row: any) => {
    //     if (row?.start_time) {
    //       return {
    //         'margin-top': ' 0.5rem',
    //         'text-align': ' center',
    //         'align-items': 'center',
    //         'display': 'flex',
    //         'justify-content': 'center',
    //         'font-weight': 'bolder',
    //         'background-color': ' var(--bs-dark)',
    //         'color': 'var(--bs-body-color)',
    //         'padding': '0.5rem',
    //       };
    //     }
    //     return {};
    //   },
    // },
  ];
}
export const invoiceDataDetail = {
  table: [
    {
      header: 'Date',
      name: 'due_or_paid_date',
      datetimeObj: true,
      dateTimeFormat: 'd MMM y',
    },
    {
      header: 'Invoice Reference',
      name: 'invoice_reference',
    },
  ],
  card: {
    individualDetails: [
      {
        name: 'due_or_paid_date',
      },
      {
        name: 'invoice_reference',
      },
    ],
  },
};
export function sortArrayOfObject(arr: any, key: any, sort_order = 'asc') {
  const order = sort_order.trim().toLowerCase();
  return arr.sort((a: any, b: any) => {
    if (a[key] === null) return order === 'asc' ? 1 : -1;
    if (b[key] === null) return order === 'asc' ? -1 : 1;
    if (a[key] > b[key]) {
      return order === 'asc' ? 1 : -1;
    } else if (a[key] < b[key]) {
      return order === 'asc' ? -1 : 1;
    }
    return 0;
  });
}
export const subscriberStatus = {
  statusValues: {
    active: 'Active',
    inactive: 'In Active',
    on_trial: 'On Trial',
    beta: 'Beta',
    disabled: 'Disabled',
    closed: 'Closed',
    locked: 'Locked',
  },
  statusStyles: {
    active: {
      'background-color': 'var(--bs-success)',
      'color': 'var(--color-basic-100)',
      'width': '70px',
      'display': 'block',
      'padding': '4px',
    },
    inactive: {
      'background-color': 'var(--bs-warning)',
      'color': 'var(--color-basic-100)',
      'width': '70px',
      'display': 'block',
      'padding': '4px',
    },
    on_trial: {
      'background-color': 'var(--bs-info)',
      'color': 'var(--color-basic-100)',
      'width': '70px',
      'display': 'block',
      'padding': '4px',
    },
    beta: {
      'background-color': 'var(--bs-secondary)',
      'color': 'var(--color-basic-100)',
      'width': '70px',
      'display': 'block',
      'padding': '4px',
    },
    disabled: {
      'background-color': 'var(--bs-warning)',
      'color': 'var(--color-basic-100)',
      'width': '70px',
      'display': 'block',
      'padding': '4px',
    },
    locked: {
      'background-color': 'var(--bs-primary)',
      'color': 'var(--color-basic-100)',
      'width': '70px',
      'display': 'block',
      'padding': '4px',
    },
    closed: {
      'background-color': 'var(--bs-danger)',
      'color': 'var(--color-basic-100)',
      'width': '70px',
      'display': 'block',
      'padding': '4px',
    },
  },
};
export function showGeoFenceAlert(
  minDistance: any,
  latitude1: any,
  latitude2: any,
  longitude1: any,
  longitude2: any
) {
  try {
    const distanceInKm: any = getDistanceUsingHaversine(
      latitude1,
      latitude2,
      longitude1,
      longitude2
    );

    return Boolean(distanceInKm * 1000 > minDistance);
  } catch (error) {
    return false;
  }
}

export function checkScanDistanceGreater(selectedEvent: any) {
  if (
    Boolean(
      selectedEvent?.checkpoint_details?.is_locked &&
        selectedEvent?.checkpoint_details?.qr_code &&
        selectedEvent?.checkpoint_details?.scan_detail &&
        selectedEvent?.checkpoint_details?.min_distance
    )
  ) {
    const distanceInKm: any = getDistanceUsingHaversine(
      selectedEvent?.checkpoint_details?.scan_detail?.lat,
      selectedEvent?.gps?.lat,
      selectedEvent?.checkpoint_details?.scan_detail?.lon,
      selectedEvent?.gps?.lon
    );
    if (distanceInKm * 1000 > selectedEvent?.checkpoint_details?.min_distance) {
      return {
        minDistance: selectedEvent?.checkpoint_details?.min_distance,
        scanDistance: distanceInKm * 1000,
      };
    }
  }
  return {};
}
