import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject, Observable, timeout } from 'rxjs';
import { LoadingSpinnerService } from './loading-spinner.service';
@Injectable({
  providedIn: 'root',
})
export class DeviceInfoService {
  deviceInfoSubject = new BehaviorSubject({});

  appStatus: boolean = true;

  constructor(
    private deviceService: DeviceDetectorService,
    private http: HttpClient,
    private spinnerService: LoadingSpinnerService
  ) {
    this.onLineOfflineCheck();
    window.addEventListener('online', () => {
      this.onLineOfflineCheck();
    });
    window.addEventListener('offline', () => {
      this.onLineOfflineCheck();
    });
    this.deviceInfoSubject.subscribe((value: any) => {
      if ('appStatus' in value) {
        this.appStatus = value?.appStatus;
      }
    });
  }
  onLineOfflineCheck() {
    this.deviceInfoSubject.next({
      appStatus: navigator.onLine && this.appStatus,
    });
  }

  async setIp() {
    try {
      this.getAwaitIP();
    } catch (error) {
      // Handle the error (e.g., log it or show a message)
      console.error('Error getting IP:', error);
    }
  }
  async handleIpApiError() {
    return await new Promise(async (resolve, reject) => {
      if (window.localStorage.getItem('ipAddress')) {
        resolve(window.localStorage.getItem('ipAddress'));
      }
      try {
        const TIMEOUT_IN_MS = 5000;
        this.backupApiCall()
          .pipe(
            timeout(TIMEOUT_IN_MS) // This will raise an error if no response is received within the timeout value
          )
          .subscribe({
            next: (resp: any) => {
              if (resp?.ipAddress) {
                window.localStorage.setItem('ipAddress', resp.ipAddress);
                resolve(resp.ipAddress);
              }
            },
            error: (err) => {
              if (err.name === 'TimeoutError') {
                resolve('null');
              } else {
                resolve('null');
              }
            },
          });
      } catch (error) {
        console.error('Error getting IP:', error);
        resolve('null');
      }
    });
  }
  backupApiCall(): Observable<any> {
    return this.http.get('https://freeipapi.com/api/json');
  }
  getIP(): Observable<any> {
    return this.http.get('https://jsonip.com');
  }
  async getAwaitIP() {
    const TIMEOUT_IN_MS = 5000;
    return await new Promise(async (resolve, reject) => {
      try {
        this.getIP()
          .pipe(
            timeout(TIMEOUT_IN_MS) // This will raise an error if no response is received within the timeout value
          )
          .subscribe({
            next: (resp: any) => {
              if (resp?.ip) {
                window.localStorage.setItem('ipAddress', resp.ip);
                resolve(resp.ip);
              }
            },
            error: async (err) => {
              if (err.name === 'TimeoutError') {
                resolve(await this.handleIpApiError());
              } else {
                // Handle other errors
              }
            },
          });
        setTimeout(() => {
          if (!window.localStorage.getItem('ipAddress')) {
            this.handleIpApiError();
          }
        }, 5000);
      } catch (error) {
        console.error('Error getting IP:', error);
        resolve(await this.handleIpApiError());
      }
    });
  }
  getGPS() {
    return this.http.get('https://freeipapi.com/api/json');
  }
  async getAwaitFreeGPS() {
    return await new Promise((resolve, reject) => {
      this.getGPS().subscribe((resp: any) =>
        resolve({
          lat: resp?.latitude,
          lon: resp?.longitude,
          source: 2,
        })
      );
    });
  }
  async watchPostionWithLowAccuracy() {
    return await new Promise((resolve, reject) => {
      let lowAccuracystartTime = performance.now();
      let lowAccuracyWatchId = navigator.geolocation.watchPosition(
        (position) => {
          if (position) {
            // if we are getting a postion we can stop the watchPostion then and there.
            navigator.geolocation.clearWatch(lowAccuracyWatchId);
          }
          return resolve({
            lat: position.coords.latitude,
            lon: position.coords.longitude,
            source: 1,
          });
        },
        async (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            // case where user has denied location access in browser
            this.spinnerService.hide();

            this.deviceInfoSubject.next({
              permissionStatus: 'locationDisabled',
            });
            // stop the clear watch in that case
            navigator.geolocation.clearWatch(lowAccuracyWatchId);
          } else {
            // add backup logic here. ie if endTime - startTime > x seconds find another way to fetch gps.
            if ((performance.now() - lowAccuracystartTime) / 1000 > 5) {
              //It's been 5 seconds since the app started to
              //fetch GPS with low accuracy.At this point,
              //we will fetch it from IP after clearing the low - accuracy watchPostion
              navigator.geolocation.clearWatch(lowAccuracyWatchId);

              resolve(await this.getAwaitFreeGPS());
            }
          }
        },
        {
          timeout: 1000,
          enableHighAccuracy: false,
          maximumAge: 5000,
        }
      );
    });
  }
  async watchPostionWithHighAccuracy() {
    return await new Promise((resolve, reject) => {
      let highAccuracystartTime = performance.now();
      let highAccuracyWatchId = navigator.geolocation.watchPosition(
        (position) => {
          if (position) {
            // if we are getting a postion we can stop the watchPostion then and there.
            navigator.geolocation.clearWatch(highAccuracyWatchId);
          }
          return resolve({
            lat: position.coords.latitude,
            lon: position.coords.longitude,
            source: 0,
          });
        },
        async (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            // case where user has denied location access in browser
            this.spinnerService.hide();
            this.deviceInfoSubject.next({
              permissionStatus: 'locationDisabled',
            }); // stop the clear watch in that case
            navigator.geolocation.clearWatch(highAccuracyWatchId);
          } else {
            // add backup logic here. ie if endTime - startTime > x seconds find another way to fetch gps.
            if ((performance.now() - highAccuracystartTime) / 1000 > 5) {
              //It's been 5 seconds since the app started to
              //fetch GPS with high accuracy.At this point,
              //we will fetch it from watchPostion with low accuracy after clearing the high-accuracywatchPostion
              navigator.geolocation.clearWatch(highAccuracyWatchId);

              resolve(await this.watchPostionWithLowAccuracy());
            }
          }
        },
        {
          timeout: 1000,
          enableHighAccuracy: true,
          maximumAge: 5000,
        }
      );
    });
  }
  async getIp() {
    return new Promise(async (resolve) => {
      resolve(
        window.localStorage.getItem('ipAddress')
          ? window.localStorage.getItem('ipAddress')
          : await this.getAwaitIP()
      );
    });
  }

  async setAndGetDeviceInfo() {
    return await new Promise(async (resolve) => {
      let deviceInfo = this.deviceService.getDeviceInfo();
      window.localStorage.setItem('deviceInfo', JSON.stringify(deviceInfo));
      resolve(deviceInfo);
    });
  }
  async getDeviceInfo() {
    return new Promise(async (resolve) => {
      try {
        const deviceInfo: any = window.localStorage.getItem('deviceInfo')
          ? window.localStorage.getItem('deviceInfo')
          : await this.setAndGetDeviceInfo();
        resolve(JSON.parse(deviceInfo));
      } catch (err) {
        resolve(this.deviceService.getDeviceInfo());
      }
    });
  }
  getGpsCoordinates = async () => {
    return await this.watchPostionWithHighAccuracy();
  };
  updateGpsCache() {
    this.getGpsCoordinates().then((gps: any) => {
      let gpsRecord = { gps: gps, recorded_at: new Date().getTime() / 1000 };
      sessionStorage.setItem('gpsRecord', JSON.stringify(gpsRecord));
    });
  }

  async getDeviceDetail() {
    return new Promise(async (resolve) => {
      let gps: any = await this.getGpsCoordinates();
      let gpsRecord = { gps: gps, recorded_at: new Date().getTime() / 1000 };
      sessionStorage.setItem('gpsRecord', JSON.stringify(gpsRecord));
      const deviceInfo: any = await this.getDeviceInfo();
      resolve({
        gps: {
          ...gps,
          ip: await this.getIp(),
        },
        ...deviceInfo,
      });
    });
  }
}
