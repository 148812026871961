import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { DeviceInfoService } from '../../../shared/services/device-info.service';

@Injectable({
  providedIn: 'root',
})
export class JobsService {
  constructor(
    private http: HttpClient,
    private deviceInfoService: DeviceInfoService
  ) {}
  fetchJobDetails(requestData?: any, params?: any) {
    return this.http
      .post('/api/job/list_jobs/', requestData, { params: params })
      .pipe(map((response) => response));
  }

  getNearestJobs(body?: any, params?: any) {
    return this.http
      .post('/api/job/list_nearest/', body, { params: params })
      .pipe(map((response) => response));
  }
  getCompletedJobs(body?: any, params?: any) {
    return this.http
      .post('/api/job/list_completed/', body, { params: params })
      .pipe(map((response) => response));
  }
  getJobParams() {
    return this.http
      .get('/api/job/get_options/')
      .pipe(map((response) => response));
  }
  async createJob(requestData: any, params?: any) {
    const body = {
      ...requestData,
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(
      this.http
        .post('api/job/', body, { params: params })
        .pipe(map((response) => response))
    );
  }

  getSearchClients(searchData: any) {
    return this.http
      .get('api/company/', { params: { search_str: searchData } })
      .pipe(map((response) => response));
  }

  getJobById(jobId: any) {
    const body = {};
    return this.http
      .get(`api/job/${jobId}`, body)
      .pipe(map((response) => response));
  }
  getMobileJobDetailById(jobId: any) {
    return this.http
      .get(`api/job/get_detail/`, { params: { job_id: jobId } })
      .pipe(map((response) => response));
  }
  async updateJob(requestData: any) {
    let data = requestData.data;

    const body = {
      ...data,
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(
      this.http.put(requestData.url, body).pipe(map((response) => response))
    );
  }

  getAssignees(param: any) {
    return this.http.get('api/users/get_active_users/', { params: param });
  }
  addNewOption(requestData: any) {
    return this.http.get(`api/${requestData.url}`);
  }
  getSNProviderBillTo() {
    return this.http.get('api/client/smartnet/provider_bill_to/');
  }

  getDashBoardData(params: any) {
    return this.http.get(`/api/dashboard/`, { params: params });
  }

  sendRenewUserLicenseNotifiaction(userId: number, licenseId: number) {
    return this.http.get(`/api/dashboard/send_notification/`, {
      params: { user_id: userId, license_id: licenseId },
    });
  }

  async scanCheckPoint(requestData: any) {
    const data = {
      ...requestData,
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(
      this.http
        .post(`/api/patrol/scan_checkpoint/`, data)
        .pipe(map((response: any) => response))
    );
  }

  async getNearestCheckpointList(body?: any, params?: any) {
    const data = {
      ...body,
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };

    return lastValueFrom(
      this.http
        .post(`/api/check_point/list_nearest/`, data, { params: params })
        .pipe(map((response: any) => response))
    );
  }
  async getNearesSiteList(body?: any, params?: any) {
    const data = {
      ...body,
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(
      this.http
        .post(`/api/site/list_nearest/`, data, { params: params })
        .pipe(map((response: any) => response))
    );
  }

  //Smart Net job APIs.
  getSmartNetJobList() {
    const body = {};
    return this.http
      .post(`/api/smart_net/?request_data=job&request_type=list`, body)
      .pipe(map((response: any) => response));
  }
  getSmartNetJobById(id: any) {
    const body = {};
    return this.http
      .post(
        `/api/smart_net/?request_data=job&request_type=retrieve&job_id=${id}/`,
        body
      )
      .pipe(map((response: any) => response));
  }
  async jobUpdate(body?: any, params?: any) {
    const data = {
      ...body,
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(
      this.http
        .post(`/api/job/update_job/`, data, { params: params })
        .pipe(map((response: any) => response))
    );
  }
  getAllQRCheckpointList(params?: any) {
    return this.http
      .get(`/api/check_point/list_qr_checkpoints/`, { params: params })
      .pipe(map((response: any) => response));
  }
  getAllClientList(params?: any) {
    return this.http
      .get(`/api/company/list_all_companies/`, { params: params })
      .pipe(map((response: any) => response));
  }
  async welfareCheckIn() {
    const data = {
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(
      this.http
        .post('api/patrol/welfare_check_in/', data)
        .pipe(map((response: any) => response))
    );
  }
  async updateMissedEvent(requestData: any) {
    const data = {
      ...requestData,
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(
      this.http
        .post(`/api/patrol/update_missed_event/`, data)
        .pipe(map((response: any) => response))
    );
  }
}
