import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'gtapp-address-lookup',
  templateUrl: './address-lookup.component.html',
  styleUrl: './address-lookup.component.scss',
})
export class AddressLookupComponent implements OnInit {
  @Input() addrLookupData: any;
  @Input()
  siteForm!: FormGroup;
  @Input() addressValue: string = '';
  @Output() emitChangesData = new EventEmitter();
  incompleteAddress: boolean = false;
  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.siteForm = this.fb.group({
      address1: ['', Validators.required],
      postcode: ['', Validators.required],
    });
  }
  editAddress() {
    // this.incompleteAddress = true;
    this.addressValue = '';
    this.emitChangesData.emit();
  }
}
