<div class="row mt-2 back-btn-height">
  <div class="col-md-5">
    <div class="mt-2">
      <div class="chat-info">
        <strong>{{ selectedEvent?.action | titlecase }}</strong>
        <span
          *ngIf="
            selectedEvent?.history_changes?.length &&
            (subscriberUserId == selectedEvent?.subs_user_id ||
              isAdmin ||
              isDispatchUser)
          "
        >
          | Edited [{{
            getLastCommentEditedDateTime(selectedEvent?.history_changes)
              ?.updated_at | date : "d MMM y HH:mm"
          }}]</span
        >
      </div>
      <div
        class="chat-data fs-6"
        (click)="
          canEditComments(selectedEvent) ? editComment(selectedEvent) : ''
        "
      >
        {{ selectedEvent?.detail }}
        <span *ngIf="selectedEvent?.eta"
          >[ETA: {{ selectedEvent?.eta }} minutes]</span
        >
        <span class="floar-end" *ngIf="canEditComments(selectedEvent)">
          <i class="fa-solid fa-pen-to-square"></i>
        </span>
      </div>
    </div>
    <hr />
    <h6
      [ngClass]="
        [1, 2, 5, 7].includes(selectedEvent?.event_action) &&
        selectedEvent?.detail
          ? 'mt-3'
          : ''
      "
    >
      Event Details
    </h6>

    <div class="row row-cols-1 row-cols-xxl-2">
      <ng-container *ngFor="let indColumn of userEventDetailsTableView">
        <ng-container
          *ngIf="
            (indColumn.nestedValue
              ? indColumn.nestedValue(selectedEvent)
              : selectedEvent[indColumn.name]
            )?.trim()
          "
        >
          <div>
            <dt style="text-transform: uppercase">
              {{ indColumn?.header }}
            </dt>
            <ng-container *ngIf="indColumn?.datetimeObj == true">
              <dd>
                {{
                  indColumn.nestedValue
                    ? (indColumn.nestedValue(selectedEvent)
                      | date
                        : (indColumn.dateTimeFormat
                            ? indColumn.dateTimeFormat
                            : "d MMM y HH:mm"))
                    : (selectedEvent[indColumn.name]
                      | date
                        : (indColumn.dateTimeFormat
                            ? indColumn.dateTimeFormat
                            : "d MMM y HH:mm"))
                }}
              </dd></ng-container
            >
            <ng-container *ngIf="!indColumn?.datetimeObj">
              <dd>
                {{
                  indColumn.nestedValue
                    ? indColumn.nestedValue(selectedEvent)
                    : selectedEvent[indColumn.name] == "null" ||
                      selectedEvent[indColumn.name] == "undefined"
                    ? "N/A"
                    : selectedEvent[indColumn.name]
                }}
              </dd></ng-container
            >
          </div>
        </ng-container>
      </ng-container>
      <div *ngIf="selectedEvent?.checkpoint_details?.min_distance">
        <dt style="text-transform: uppercase">Geofence Radius (metres)</dt>
        <dd>
          {{
            selectedEvent?.checkpoint_details?.min_distance | number : "1.0-0"
          }}
        </dd>
      </div>
    </div>
    <div *ngIf="scanDistanceInfo?.scanDistance" class="fw-bold text-danger">
      <span class="badge-value">
        Scanned {{ scanDistanceInfo?.scanDistance }}M away from the
        Checkpoint</span
      >
    </div>
    <div
      *ngIf="selectedEvent?.recorded_at && !selectedEvent?.delayed_event"
      class="mb-2"
    >
      <dt class="text-danger fw-bold d-flex align-items-center">
        CAPTURED TIME
        <span
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          class="ms-2"
          [attr.title]="'This event was captured when the user was offline'"
        >
          <i class="fa-solid fa-circle-info"></i>
        </span>
      </dt>
      <dd>{{ selectedEvent?.recorded_at | date : "d MMM y HH:mm" }}</dd>
    </div>

    <div class="mt-3">
      <ng-container *ngIf="selectedEvent?.prompts?.length">
        <div *ngFor="let item of selectedEvent?.prompts" class="mt-2">
          <div class="fw-bold">
            {{ item?.prompt_data?.name ? item?.prompt_data?.name : "Prompt" }}
          </div>

          <div *ngFor="let dataItem of item?.data">
            <ng-template
              *ngTemplateOutlet="promptTemplate; context: { value: dataItem }"
            ></ng-template>
          </div>
        </div>
      </ng-container>
      <ng-container
        *ngIf="selectedEvent?.device_details && (isAdmin || isDispatchUser)"
      >
        <h6>Device Details</h6>

        <div class="row row-cols-1 row-cols-lg-2">
          <div *ngFor="let item of selectedEvent?.device_details | keyvalue">
            <dl *ngIf="item?.value">
              <dt class="text-uppercase">
                {{ item.key }}
              </dt>
              <dd class="text-break">{{ item.value }}</dd>
            </dl>
          </div>

          <div>
            <dt
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="{{browserGpsInfo?.[selectedEvent?.gps?.source]?.description}}"
              [ngClass]="browserGpsInfo?.[selectedEvent?.gps?.source] ? 'strong' : ''"
            >
              LOCATION
            </dt>
            <dd
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="{{browserGpsInfo?.[selectedEvent?.gps?.source]?.description}}"
              [ngClass]="browserGpsInfo?.[selectedEvent?.gps?.source] ? 'danger-text': ''"
            >
              {{ selectedEvent?.gps?.lat }},
              {{ selectedEvent?.gps?.lon }}
            </dd>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="col-md-7">
    <div
      [ngClass]="selectedEvent?.file ? 'row row-cols-md-2' : 'row row-cols-1'"
    >
      <div
        class="col-md-6 my-2"
        *ngIf="selectedEvent?.file"
        (click)="onOpenFile(selectedEvent?.file)"
      >
        <img
          class="event-image"
          src="{{ selectedEvent?.file }}"
          alt="unplash"
        />
      </div>
      <div
        [ngClass]="selectedEvent?.file ? 'col-md-6' : 'col'"
        *ngIf="isAdmin || isDispatchUser"
      >
        <gtapp-map-leaflet
          *ngIf="selectedEvent?.gps"
          [latLon]="selectedEvent?.gps"
          [scannedCheckpointDetails]="selectedEvent?.checkpoint_details"
          [mapId]="'event-location'"
        ></gtapp-map-leaflet>
      </div>
    </div>
  </div>
</div>
<div class="row mt-3 bottom-section" id="fixedBtn">
  <div class="px-3 pb-2">
    <button
      class="btn btn-dark d-block d-md-none w-100 btn-lg"
      (click)="hideDetails()"
    >
      Back
    </button>
  </div>
</div>

<!--looped prompt template-->
<ng-template let-dataItem="value" #promptTemplate>
  <div *ngIf="dataItem?.description">
    <dt style="text-transform: uppercase">{{ dataItem?.description }}</dt>

    <dd>{{ dataItem | misc : "promptValue" }}</dd>
  </div>

  <div *ngFor="let option of dataItem?.options">
    <ng-template
      *ngTemplateOutlet="promptTemplate; context: { value: option }"
    ></ng-template>
  </div>
</ng-template>
