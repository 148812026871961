import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { Observable, fromEvent } from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';

/**
 * Keyboard events
 */
const isArrowUp = (keyCode: number) => keyCode === 38;
const isArrowDown = (keyCode: number) => keyCode === 40;
const isArrowUpDown = (keyCode: any) =>
  isArrowUp(keyCode) || isArrowDown(keyCode);
const isEnter = (keyCode: number) => keyCode === 13;
const isBackspace = (keyCode: number) => keyCode === 8;
const isDelete = (keyCode: number) => keyCode === 46;
const isESC = (keyCode: number) => keyCode === 27;
const isTab = (keyCode: number) => keyCode === 9;

@Component({
  selector: 'gtapp-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrl: './auto-complete.component.scss',
})
export class AutoCompleteComponent
  implements OnInit, OnChanges, AfterViewInit, ControlValueAccessor
{
  @ViewChild('searchInput')
  searchInput!: ElementRef; // input element
  @ViewChild('filteredListElement') filteredListElement: ElementRef | undefined; // element of items

  inputKeyUp$!: Observable<any>; // input events
  inputKeyDown$!: Observable<any>; // input events

  public query = ''; // search query
  public filteredList: any = []; // list of items

  public elementRef;
  public selectedIdx = -1;
  public toHighlight: string = '';
  public isFocused = false;
  public hideSearchButton: boolean = false;
  public isOpen = false;
  public overlay = false;
  private manualOpen: boolean = false;
  private manualClose: boolean = false;
  showDropDown: boolean = false;
  // @Inputs
  /**
   * Data of items list.
   * It can be array of strings or array of objects.
   */
  @Input() public data = [];

  @Input() public placeHolder = '';
  @Input() public inputId = '';
  @Input() public initialValue = '';
  @Input() public searchKeyword = '';
  // give the close icon option to delete list and the entered value
  @Input() public enableSearchCloseOption: boolean = false;
  // Custom templates
  @Input() itemTemplate!: TemplateRef<any>;

  // if true then add an extra key at the begining of the list for performing misc actions like adding site,client etc
  @Input() addInitialKey: boolean = false;

  // @Output events
  /** Event that is emitted whenever an item from the list is selected. */
  @Output() selected = new EventEmitter<any>();

  /** Event that is emitted whenever an input is changed. */
  @Output() inputChanged = new EventEmitter<any>();

  /** Event that is emitted whenever an input is focused. */
  @Output() readonly inputFocused = new EventEmitter<any>();
  /** Event that is emitted whenever an input is cleared. */
  @Output() readonly inputCleared: EventEmitter<void> =
    new EventEmitter<void>();

  /** Event that is emitted when the autocomplete panel is opened. */
  @Output() readonly opened: EventEmitter<void> = new EventEmitter<void>();

  /** Event that is emitted when the autocomplete panel is closed. */
  @Output() readonly closed: EventEmitter<void> = new EventEmitter<void>();

  /** Event that is emitted when scrolled to the end of items. */
  @Output() readonly scrolledToEnd: EventEmitter<void> =
    new EventEmitter<void>();
  /** Event that is emitted when enter key/search icon is pressed */
  @Output() submitted = new EventEmitter<any>();

  /** Event that is emitted when close icon icon is pressed */
  @Output() searchClosed = new EventEmitter<any>();

  /**
   * Propagates new value when model changes
   */
  propagateChange: any = () => {};

  /**
   * Writes a new value from the form model into the view,
   * Updates model
   */
  writeValue(value: any) {
    this.query = value;
  }

  /**
   * Registers a handler that is called when something in the view has changed
   */
  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  /**
   * Registers a handler specifically for when a control receives a touch event
   */
  registerOnTouched(fn: () => void): void {}

  /**
   * Event that is called when the value of an input element is changed
   */
  onChange(event: any) {
    this.hideSearchButton = false;
    this.propagateChange(event.target.value);
    if (event.target.value.length === 0) {
      this.isFocused = false;
    } else if (event.target.value?.length > 0) {
      this.isFocused = true;
      this.inputChanged.emit({ target: { value: event.target.value } });
    }
  }

  constructor(elementRef: ElementRef, private renderer: Renderer2) {
    this.elementRef = elementRef;
  }

  ngOnInit() {
    if (this.initialValue) {
      this.query = this.initialValue;
    }
  }

  ngAfterViewInit() {
    this.initEventStream();
  }

  /**
   * Update search results
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (this.initialValue && !this.query) {
      this.query = this.initialValue;
    }
    if (
      changes &&
      changes['data'] &&
      Array.isArray(changes['data'].currentValue)
    ) {
      if (this.addInitialKey) {
        this.filteredList = [...(this.data || [])];
        this.filteredList?.unshift({ key: 0 });
      }
      this.handleItemsChange();

      if (!changes['data'].firstChange) {
        this.handleOpen();
        this.handleItemsChange();
      }
    }
  }
  inputSubmitted() {
    this.hideSearchButton = true;
    this.submitted.emit(this.query);
  }
  onCloseSearch() {
    this.query = '';
    this.filteredList = [];
    this.searchClosed.emit(true);
  }
  /**
   * Items change
   */
  public handleItemsChange() {
    if (!this.isOpen) {
      return;
    }

    this.filteredList = [...(this.data || [])];

    if (this.addInitialKey) {
      this.filteredList?.unshift({ key: 0 });
    }
  }

  /**
   * Check type of item in the list.
   * @param item
   */
  isType(item: any) {
    return typeof item === 'string';
  }

  /**
   * Select item in the list.
   * @param item
   */
  public selectDropDownOption(item: any) {
    this.query = this.isType(item) ? item : item[this.searchKeyword];

    this.isOpen = true;
    this.overlay = false;

    this.selected.emit(item);
    this.propagateChange(item);

    this.handleClose();
  }

  /**
   * Document click
   * @param e event
   */
  public handleClick(e: { target: any }) {
    let clickedComponent = e.target;
    let inside = false;
    do {
      if (clickedComponent === this.elementRef.nativeElement) {
        inside = true;
        if (this.filteredList.length) {
          this.handleOpen();
        }
      }
      clickedComponent = clickedComponent.parentNode;
    } while (clickedComponent);
    if (!inside) {
      this.handleClose();
    }
  }

  /**
   * Handle body overlay
   */
  handleOverlay() {
    this.overlay = false;
    this.isOpen = false;
  }

  /**
   * Define panel state
   */
  setPanelState(event: any) {
    if (event) {
      event.stopPropagation();
    }
    // If controls are untouched
    if (
      typeof this.manualOpen === 'undefined' &&
      typeof this.manualClose === 'undefined'
    ) {
      this.isOpen = false;
      this.handleOpen();
    }

    // If one of the controls is untouched and other is deactivated
    if (
      (typeof this.manualOpen === 'undefined' && this.manualClose === false) ||
      (typeof this.manualClose === 'undefined' && this.manualOpen === false)
    ) {
      this.isOpen = false;
      this.handleOpen();
    }

    // if controls are touched but both are deactivated
    if (this.manualOpen === false && this.manualClose === false) {
      this.isOpen = false;
      this.handleOpen();
    }

    // if open control is touched and activated
    if (this.manualOpen) {
      this.isOpen = false;
      this.handleOpen();
      this.manualOpen = false;
    }

    // if close control is touched and activated
    if (this.manualClose) {
      this.isOpen = true;
      this.handleClose();
      this.manualClose = false;
    }
  }

  /**
   * Manual controls
   */
  open() {
    this.manualOpen = true;
    this.isOpen = false;
    this.handleOpen();
  }

  close() {
    this.manualClose = true;
    this.isOpen = true;
    this.handleClose();
  }

  focus() {
    this.handleFocus(event);
  }

  clear() {
    this.remove(event);
  }

  /**
   * Remove search query
   */
  public remove(e: any) {
    e.stopPropagation();
    this.query = '';
    this.inputCleared.emit();
    this.propagateChange(this.query);
    this.setPanelState(e);
  }

  handleOpen() {
    if (this.isOpen) {
      return;
    }
    // If data exists
    if (this.data && this.data.length) {
      this.isOpen = true;
      this.overlay = true;
      // this.filterList();
      this.opened.emit();
    }
  }

  handleClose() {
    if (!this.isOpen) {
      this.isFocused = false;
      return;
    }
    this.isOpen = false;
    this.overlay = false;
    this.filteredList = [];
    this.selectedIdx = -1;

    this.isFocused = false;
    this.closed.emit();
  }

  handleFocus(e: void | Event) {
    this.searchInput.nativeElement.focus();
    if (this.isFocused) {
      return;
    }
    this.inputFocused.emit(e);
    // if data exists then open
    if (this.data && this.data.length) {
      this.setPanelState(event);
    }
    this.isFocused = true;
  }
  handleBlur(event: any) {
    // this.isFocused = false;
    setTimeout(() => {
      this.isFocused = false;
    }, 200);
  }
  /**
   * Initialize keyboard events
   */
  initEventStream() {
    this.inputKeyUp$ = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      map((e: any) => e)
    );

    this.inputKeyDown$ = fromEvent(
      this.searchInput.nativeElement,
      'keydown'
    ).pipe(map((e: any) => e));

    this.listenEventStream();
  }

  /**
   * Listen keyboard events
   */
  listenEventStream() {
    // key up event
    this.inputKeyUp$
      .pipe(
        filter(
          (e) =>
            !isArrowUpDown(e.keyCode) &&
            !isEnter(e.keyCode) &&
            !isESC(e.keyCode) &&
            !isTab(e.keyCode)
        )
      )
      .subscribe((e) => {
        this.onKeyUp(e);
      });

    // cursor up & down
    this.inputKeyDown$
      .pipe(filter((e) => isArrowUpDown(e.keyCode)))
      .subscribe((e) => {
        e.preventDefault();
        this.onFocusItem(e);
      });

    // enter keyup
    this.inputKeyUp$.pipe(filter((e) => isEnter(e.keyCode))).subscribe((e) => {
      //this.onHandleEnter();
    });

    // enter keydown
    this.inputKeyDown$
      .pipe(filter((e) => isEnter(e.keyCode)))
      .subscribe((e) => {
        this.onHandleEnter();
      });

    // ESC
    this.inputKeyUp$
      .pipe(filter((e) => isESC(e.keyCode), debounceTime(100)))
      .subscribe((e) => {
        this.onEsc();
      });

    // TAB
    this.inputKeyDown$.pipe(filter((e) => isTab(e.keyCode))).subscribe((e) => {
      this.onTab();
    });

    // delete
    this.inputKeyDown$
      .pipe(filter((e) => isBackspace(e.keyCode) || isDelete(e.keyCode)))
      .subscribe((e) => {
        this.onDelete();
      });
  }

  /**
   * on keyup == when input changed
   * @param e event
   */
  onKeyUp(e: { target: { value: any } }) {
    // if input is empty
    if (!this.query) {
      if (e.target.value?.length > 0) {
        this.inputChanged.emit({ target: { value: e.target.value } });
      }

      this.inputCleared.emit();
      //this.filterList();
      this.setPanelState(e);
    }
    // note that '' can be a valid query
    if (!this.query && this.query !== '') {
      return;
    }
  }

  /**
   * Keyboard arrow top and arrow bottom
   * @param e event
   */
  onFocusItem(e: { key: string }) {
    // move arrow up and down on filteredList or historyList

    // filteredList
    const totalNumItem = this.filteredList.length;
    if (e.key === 'ArrowDown') {
      let sum = this.selectedIdx;
      sum = this.selectedIdx === null ? 0 : sum + 1;
      this.selectedIdx = (totalNumItem + sum) % totalNumItem;
      this.scrollToFocusedItem(this.selectedIdx);
    } else if (e.key === 'ArrowUp') {
      if (this.selectedIdx == -1) {
        this.selectedIdx = 0;
      }
      this.selectedIdx = (totalNumItem + this.selectedIdx - 1) % totalNumItem;
      this.scrollToFocusedItem(this.selectedIdx);
    }
  }

  /**
   * Scroll to focused item
   * * @param index
   */
  scrollToFocusedItem(index: number) {
    let listElement = null;

    // filteredList element
    listElement = this.filteredListElement?.nativeElement;

    const items = Array.prototype.slice
      .call(listElement.childNodes)
      .filter((node: any) => {
        if (node.nodeType === 1) {
          // if node is element
          return node.className.includes('item');
        } else {
          return false;
        }
      });

    if (!items.length) {
      return;
    }

    const listHeight = listElement.offsetHeight;
    const itemHeight = items[index].offsetHeight;
    const visibleTop = listElement.scrollTop;
    const visibleBottom = listElement.scrollTop + listHeight - itemHeight;
    const targetPosition = items[index].offsetTop;

    if (targetPosition < visibleTop) {
      listElement.scrollTop = targetPosition;
    }

    if (targetPosition > visibleBottom) {
      listElement.scrollTop = targetPosition - listHeight + itemHeight;
    }
  }

  /**
   * Select item on enter click
   */
  onHandleEnter() {
    // click enter to choose item from filteredList or historyList
    if (this.selectedIdx > -1) {
      this.query = !this.isType(this.filteredList[this.selectedIdx])
        ? this.filteredList[this.selectedIdx][this.searchKeyword]
        : this.filteredList[this.selectedIdx];

      this.selectDropDownOption(this.filteredList[this.selectedIdx]);
    }
    this.handleClose();
  }

  /**
   * Esc click
   */
  onEsc() {
    this.searchInput.nativeElement.blur();
    this.handleClose();
  }

  /**
   * Tab click
   */
  onTab() {
    this.searchInput.nativeElement.blur();
    this.handleClose();
  }

  /**
   * Delete click
   */
  onDelete() {
    // panel is open on delete
    this.isOpen = true;
  }
  clearInput() {
    this.query = '';
    this.initialValue = '';
    this.focus();
  }
}
