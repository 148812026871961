import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, map } from 'rxjs';
import { DeviceInfoService } from '../../../shared/services/device-info.service';

@Injectable({
  providedIn: 'root',
})
export class ClientsService {
  constructor(
    private http: HttpClient,
    private deviceInfoService: DeviceInfoService
  ) {}

  async addCompany(requestData: any) {
    const body = {
      ...requestData,
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(
      this.http
        .post('/api/company/', body)
        .pipe(map((response: any) => response))
    );
  }
  async updateCompanyDetails(requestData?: any, params?: any) {
    const body = {
      ...requestData,
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(
      this.http
        .post('/api/company_profile/', body, { params: params })
        .pipe(map((response) => response))
    );
  }
  async addCompanyLicense(requestData: any) {
    const body = {
      ...requestData,
    };
    return this.http
      .post('/api/company_profile/add_subscriber_license/', body)
      .pipe(map((response) => response));
  }

  async updateCompanyDetailsById(requestData: any) {
    const body = {
      ...requestData,
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(
      this.http
        .put(`/api/company/${requestData.id}/`, body)
        .pipe(map((response) => response))
    );
  }
  async enableDisableCompanyById(id: any) {
    let body = {
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(
      this.http
        .patch(`/api/company/${id}/`, body)
        .pipe(map((response) => response))
    );
  }
  async enableDisableSiteById(id: any) {
    let body = {
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(
      this.http
        .patch(`/api/site/${id}/`, body)
        .pipe(map((response) => response))
    );
  }
  async addCustomerContactById(requestData: any) {
    const body = {
      ...requestData.data,
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(
      this.http
        .put(`/api/company/${requestData.id}/add_contact/`, body)
        .pipe(map((response) => response))
    );
  }
  async addSiteContactById(requestData: any) {
    const body = {
      ...requestData.data,
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(
      this.http
        .put(`/api/site/${requestData.id}/add_contact/`, body)
        .pipe(map((response) => response))
    );
  }
  fetchCustomerList(params?: any) {
    return this.http
      .get('/api/company/', { params: params })
      .pipe(map((response) => response));
  }
  getCompanyDetailById(id: any, params?: any) {
    return this.http.get(`/api/company/${id}/`, { params: params });
  }
  getCustomerContacts(id: any) {
    return this.http.get(`/api/company/${id}/contacts/`);
  }
  async updateClient(id: any, body: any, params?: any) {
    body = {
      ...body,
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(
      this.http
        .post(`/api/company/${id}/update/`, body, { params: params })
        .pipe(map((response) => response))
    );
  }
  async updateSite(id: any, body: any, params?: any) {
    body = {
      ...body,
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(
      this.http
        .post(`/api/site/${id}/update/`, body, { params: params })
        .pipe(map((response) => response))
    );
  }

  getCustomerReport(id: any, requestData: any) {
    return this.http.post(`/api/company/${id}/generate_report/`, requestData);
  }
  getSiteContacts(id: any, params?: any) {
    return this.http.get(`/api/site/${id}/contacts/`, { params: params });
  }
  searchLocationContacts(params: any) {
    return this.http.get(`/api/contacts/`, { params: params });
  }

  updateLocationContact(id: any, body: any, params?: any) {
    return this.http
      .put(`/api/contacts/${id}/`, body, { params: params })
      .pipe(map((response) => response));
  }
  getSites(params?: any) {
    return this.http.get(`api/site/`, { params: params });
  }

  async addSiteInClient(requestData: any) {
    const body = {
      ...requestData.data,
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(
      this.http.post(`/api/site/`, body).pipe(map((response) => response))
    );
  }
  async updateSiteById(requestData: any, id: any) {
    const body = {
      ...requestData.data,
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(
      this.http.put(`api/site/${id}/`, body).pipe(map((response) => response))
    );
  }
  getSiteById(id: any) {
    return this.http.get(`api/site/${id}/`);
  }
  async deleteClientContact(id: any, contactId: any, contact_type: any) {
    let body = {
      contact_id: contactId,
      contact_type: contact_type,
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(
      this.http.put(`api/company/${id}/delete_contact/`, body)
    );
  }
  async deleteSiteContact(id: any, contactId: any, contact_type: any) {
    let body = {
      contact_id: contactId,
      contact_type: contact_type,
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(this.http.put(`api/site/${id}/delete_contact/`, body));
  }
  async deleteLicense(id: any, is_subscriber_license: any) {
    const body = {
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(
      this.http.post(`api/security_license/${id}/delete_license/`, body, {
        params: { is_subscriber: is_subscriber_license },
      })
    );
  }
  editLocationContact(id: any, params?: any) {
    return this.http.get(`api/contacts/${id}/`, {
      params: params,
    });
  }

  downloadTemplateFile() {
    const downloadHttpOption = {
      responseType: 'blob' as 'json',
    };
    return this.http.get(`/api/file_import/`, downloadHttpOption);
  }

  //Monitoring Companies related APIs
  fetchMonitoringCompanyList(params?: any) {
    return this.http
      .get('/api/monitoring_company/', { params: params })
      .pipe(map((response) => response));
  }
  async createMonitoringCompany(requestData?: any, params?: any) {
    const body = {
      ...requestData,
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(
      this.http
        .post('/api/monitoring_company/', body)
        .pipe(map((response) => response))
    );
  }
  async updateMonitoringCompanyDetailById(requestData: any) {
    const body = {
      ...requestData,
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(
      this.http
        .put(`/api/monitoring_company/${requestData.id}/`, body)
        .pipe(map((response) => response))
    );
  }
  deleteCompany(id: any, params?: any) {
    let body = {};
    return this.http.put(`api/monitoring_company/${id}/`, body, {
      params: params,
    });
  }

  //Response Type related APIs
  fetchResponseTypeList(params?: any) {
    return this.http
      .get('/api/response_type/', { params: params })
      .pipe(map((response) => response));
  }
  async createResponseType(requestData?: any, params?: any) {
    const body = {
      ...requestData,
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(
      this.http
        .post('/api/response_type/', body)
        .pipe(map((response) => response))
    );
  }
  async updateResponseTypeDetailById(requestData: any) {
    const body = {
      ...requestData,
      device_info: await this.deviceInfoService.getDeviceDetail(),
    };
    return lastValueFrom(
      this.http
        .put(`/api/response_type/${requestData.id}/`, body)
        .pipe(map((response) => response))
    );
  }
  deleteResponseType(id: any, params?: any) {
    let body = {};
    return this.http.put(`api/response_type/${id}/`, body, {
      params: params,
    });
  }
  addContactSubscriberUser(requestData: any) {
    const body = {
      ...requestData.data,
    };
    return this.http
      .post(`/api/contacts/`, body)
      .pipe(map((response) => response));
  }
}
