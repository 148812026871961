import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import {
  FormControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DIALOG_DATA } from '../../../../global.variable';
import { AppService } from '../../../app.service';
import { ClientsService } from '../../../pages/pages/clients/clients.service';
import { ProfileService } from '../../../pages/profile/profile.service';
import { LoadingSpinnerService } from '../../services/loading-spinner.service';
import { ToasterService } from '../../services/toaster.service';
import { DialogRef } from '../modal-dialogue/model-dialogue.ref';
import { ModelDialogueService } from '../modal-dialogue/model-dialogue.service';
import { SaveNewAddressComponent } from '../save-new-address/save-new-address.component';

@Component({
  selector: 'gtapp-add-edit-client',
  templateUrl: './add-edit-client.component.html',
  styleUrl: './add-edit-client.component.scss',
})
export class AddEditClientComponent implements OnInit {
  isAdmin: any;
  enteredAddresslookup: boolean;
  currentStep: number = 1;
  @Input() updateView: any;
  @Input() companyData: any;
  @Input() customerName: any;
  addrLookupData: any;
  addressObjectValue: string | undefined;
  nameForm: any;
  diffForm: any;
  startForm: any;
  clientForm: UntypedFormGroup = new UntypedFormGroup({
    company_name: new UntypedFormControl('', [
      Validators.required,
      Validators.max(30),
    ]),
    addressLookup: new UntypedFormControl(''),
    is_individual: new UntypedFormControl(0),
    address1: new UntypedFormControl('', [Validators.required]),
    address2: new UntypedFormControl('', [Validators.required]),
    address3: new UntypedFormControl(' '),
    city_name: new UntypedFormControl('', [Validators.required]),
    postcode: new UntypedFormControl(
      '',
      Validators.compose([Validators.required, Validators.max(99999999)])
    ),
    state_code: new UntypedFormControl('', [Validators.required]),
    longitude: new UntypedFormControl('', [Validators.required]),
    latitude: new UntypedFormControl('', [Validators.required]),
    is_new_ui: new UntypedFormControl(true),
  });
  searchControl: FormControl = new FormControl();
  formSubmitted: any;
  country: any;
  state: any;
  selectedItem: any;
  addressValue: any;
  isIndividual: any;
  stateData: any = [];
  countryData: any;
  ipAddress: any;
  companyTypes = [
    { name: 'Business', value: 0, status: 'primary' },
    { name: 'Individual', value: 1, status: 'info' },
  ];
  linearMode = true;
  showMap: boolean = false;
  lastApiResponseHadData: boolean = true;
  lastSearchStr: any;
  apiCallMade: boolean = false;
  showAddressDropdown: boolean = false;
  addNewPlace: boolean = false;
  constructor(
    @Optional() protected dialogRef: DialogRef,
    private spinnerService: LoadingSpinnerService,
    private customerService: ClientsService,
    private appService: AppService,
    private profileService: ProfileService,
    private dialogService: ModelDialogueService,
    @Inject(DIALOG_DATA) public dialogueData: any,
    private toasterService: ToasterService
  ) {
    this.ipAddress = window.localStorage.getItem('ipAddress');
    this.enteredAddresslookup = false;
  }

  ngOnInit(): void {
    this.isIndividual = false;
    this.updateView = this.dialogueData?.updateView;
    this.companyData = this.dialogueData?.companyData;
    this.customerName = this.dialogueData?.customerName;

    this.clientForm.addControl('state_code', new UntypedFormControl());
    if (this.updateView === true) {
      this.clientForm.reset(this.companyData.address);
      this.clientForm.controls['postcode'].setValue(
        this.companyData.address.postcode
      );
      this.clientForm.controls['company_name'].setValue(
        this.companyData.company_name
      );

      this.clientForm.controls['city_name'].setValue(
        this.companyData.address.city_name
      );
      this.clientForm.controls['state_code'].setValue(
        this.companyData.address.state_code
      );
      this.country = this.companyData.address.country_name;
      this.state = this.companyData.address.state_code;
    }

    if (!this.updateView) {
      this.clientForm.removeControl('name');
      this.clientForm.addControl('company_name', new UntypedFormControl());
      this.clientForm.addControl('is_individual', new UntypedFormControl());
      this.clientForm.controls['is_individual'].setValue(0);
      this.clientForm.controls['is_individual'].valueChanges.subscribe(
        (value: any) => {
          this.clientForm.get('company_name')?.setValue(null);
        }
      );
      if (this.customerName?.trim()) {
        this.clientForm.controls['company_name'].setValue(
          this.customerName?.trim()
        );
      }
    }
    this.clientForm.addControl('country_name', new UntypedFormControl());
    this.getCountries();
  }
  toggleLinearMode() {
    this.linearMode = !this.linearMode;
  }
  changeCompanyType(type: any) {
    if (type == 'Business') {
      this.isIndividual = false;
    } else {
      this.isIndividual = true;
      this.clientForm.get('company_name')?.clearValidators();
      this.clientForm.get('is_individual')?.setValue(1);
      this.clientForm.removeControl('name');
    }
    this.shiftFocus('company_name');
  }
  changeAddress(state: any) {
    this.clientForm.controls['postcode'].setValue(null);
  }
  getCountries(data?: any) {
    this.appService.getCountries().subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.countryData = response['data'];
        let defaultCountry = this.countryData.filter(
          (country: any) => country.name == 'Australia'
        )[0];
        if (defaultCountry) {
          this.clientForm.get('country_name')?.setValue(defaultCountry.name);
          this.getStates(defaultCountry.id);
        } else {
          this.clientForm.get('country_name')?.setValue('Australia');
        }
      } else {
      }
    });
  }
  getStates(countryId: any) {
    this.appService.getStates(+countryId).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.stateData = response['data'];
      } else {
      }
    });
  }
  onAddressSearch(addressSearch: any) {
    if (addressSearch?.target?.value?.length > 2) {
      if (this.lastSearchStr) {
        !this.searchControl.value.includes(this.lastSearchStr)
          ? (this.apiCallMade = true)
          : (this.apiCallMade = false);
      } else {
        this.apiCallMade = true;
      }
      if (this.apiCallMade || this.lastApiResponseHadData) {
        this.profileService
          .addressLookup(addressSearch.target.value)
          .subscribe((res: any) => {
            this.addrLookupData = res['data'];
            this.showAddressDropdown = true;

            this.lastApiResponseHadData = res['data'].length > 0;

            this.lastSearchStr = this.searchControl.value;
          });
      }
    }
  }
  addressSelected(address: any) {
    if (address?.key === 0) {
      this.addPlace();
    } else if (address && typeof address === 'object') {
      this.addrLookupData = undefined;
      this.addressValue = address?.address1 + ' ' + address?.address2;
      this.addressObjectValue = address;
      this.clientForm.patchValue({
        address1: address?.address1 || '',
        address2: address?.address2 || '',
        address3: address?.address3 || '',
        city_name: address?.city_name,
        postcode: address?.postcode,
        state_code: address?.state_code,
        country_name: address?.country_name,
        latitude: address?.latitude,
        longitude: address?.longitude,
      });
      this.searchControl.setValue(address.full_address);

      this.enteredAddresslookup = true;
    }
  }
  onCloseDialogue(companyData?: any) {
    if (companyData) {
      this.dialogRef.close(companyData);
    } else {
      this.dialogRef.close(false);
    }
  }
  onClearForm() {
    this.clientForm.reset();
  }
  closeUpdateCustomer() {
    this.dialogRef.close(false);
  }
  getContactFormDetails(event: any) {}
  updateData() {
    this.formSubmitted = true;

    if (this.clientForm.valid) {
      var requestData = this.clientForm.value;

      this.spinnerService.show();
      if (!this.updateView) {
        this.customerService.addCompany(requestData).then((response: any) => {
          if (response['status'] == 'success') {
            this.spinnerService.hide();
            let subscriberStats = JSON.parse(
              localStorage.getItem('subscriberStats') || '{}'
            );

            subscriberStats.company_count =
              (subscriberStats?.company_count || 0) + 1;

            localStorage.setItem(
              'subscriberStats',
              JSON.stringify(subscriberStats)
            );

            this.onCloseDialogue(response);
            this.clientForm.reset();

            this.updateView = false;
          } else {
            this.spinnerService.hide();
            this.toasterService.setMessage({
              errorMessage: response['message'],
              successMessage: '',
            });
          }
        });
      } else {
        requestData['id'] = this.companyData.id;
        this.customerService
          .updateCompanyDetailsById(requestData)
          .then((response: any) => {
            if (response['status'] == 'success') {
              this.spinnerService.hide();

              this.toasterService.setMessage({
                successMessage: response['message'],
                errorMessage: '',
              });

              this.onCloseDialogue(response);

              this.updateView = false;
            } else {
              this.spinnerService.hide();
              this.toasterService.setMessage({
                errorMessage: response['message'],
                successMessage: '',
              });
            }
          });
      }
    } else {
      this.toasterService.setMessage({
        errorMessage: 'Please fill the required fields',
        successMessage: '',
      });
    }
  }
  addPlace() {
    this.showMap = false;
    this.addNewPlace = true;
    const dialogRef = this.dialogService.open(SaveNewAddressComponent, {});
    dialogRef.afterClosed().subscribe((value: any) => {
      if (value != 'close') {
        if (value) {
          this.addressSelected(value);
          if (this.searchControl.value === ' ') {
            this.searchControl.setValue(
              value?.address1
                ? value?.address1
                : '' +
                    ' ' +
                    value?.address2 +
                    ' ' +
                    value?.city_name +
                    ' ' +
                    value?.state_code +
                    ' ' +
                    value?.postcode
            );
          }
        }
      } else {
        this.searchControl.setValue('');
      }
    });
  }
  previousStepper() {
    this.currentStep = 1;
  }
  nextStepper(event: any) {
    if (this.clientForm?.value?.company_name) {
      this.currentStep = 2;
    }
  }
  shiftFocus(elementId: string) {
    setTimeout(() => {
      var element = <HTMLInputElement>document.getElementById(elementId);
      element.focus();
    });
  }
  forceUppercaseConditionally(event: any) {
    this.clientForm.controls['city_name'].setValue(
      event.target.value.toUpperCase()
    );
  }
}
