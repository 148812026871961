import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'gtapp-infinite-list',
  templateUrl: './infinite-list.component.html',
  styleUrl: './infinite-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfiniteListComponent implements OnInit {
  @Input() showTableFrom: any;

  @Output() actionClick = new EventEmitter();
  @Output() rowClick = new EventEmitter();

  @Input() mobileCardUIData: any;
  @Input() mobileCardBackendData: any;
  @Input() mobileCardDetails: any = [];
  @Output() loadMoreData = new EventEmitter();
  @Input() cardStyle: any;
  @Input() otherParams: any;
  @Input() mobileActionIcons: any;
  @Input() threshold: number = 1500;

  @Output() searchColumn = new EventEmitter<any>();
  @Output() sortColumn = new EventEmitter<any>();
  @Output() paginationChange = new EventEmitter<any>();
  apiFiltering: boolean = false;
  openSearchBox: boolean = false;
  selectedIndex: any;

  mobileDataTemp: any;
  cardData: any;
  appliedFilters: any = [];

  sortKeys: any;
  dialogeRef: any;

  //pagination params

  @Input() totalRows: number = 0;
  previousRows: number = 0;
  rows: number = 10;
  pageNum: number = 1;
  totalPages: number = 0;

  // filter and sort params for UI
  selectedCardDetail: any = {};
  filterSortData: any = [];
  currentFilterSortIndex: number = 0;
  private scrollEventListener: any;

  constructor() {}
  ngOnInit(): void {
    this.scrollEventListener = this.onWindowScroll.bind(this);
    window.addEventListener('scroll', this.scrollEventListener);
  }

  onActionClick(event: any, type: any) {
    this.actionClick.emit({ event, type });
  }

  onRowClick(rowData: any) {
    this.rowClick.emit(rowData);
  }

  ngOnDestroy(): void {
    window.removeEventListener('scroll', this.scrollEventListener);
  }

  onWindowScroll(): void {
    const scrollPosition = window.innerHeight + window.scrollY;
    const maxScroll = document.body.offsetHeight - this.threshold;

    if (
      scrollPosition >= maxScroll &&
      this.mobileCardBackendData.length < this.totalRows
    ) {
      this.loadMoreData.emit();
    }
  }
}
